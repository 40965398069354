import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import DetailedStepsPics from "../components/DetailedStepsPics"
import Features from "../components/Features"
import RecyclingShort from "../components/RecyclingShort"
import FundraisingShort from "../components/FundraisingShort"
import Map from "../components/Map"
import ContactForm from "../components/ContactForm"
import PostSubmit from "../components/PostSubmit"

export const LocationsPostTemplate = ({
  title,
  subtitle,
  breadcrumbs,
  detailedStepsPics,
  servicesAvailable,
  commoditiesAvailable,
  fundraisingShort,
  map,
  isCms,
}) => {
  const [sent, setSent] = useState({
    status: false,
    title: "Thanks for reaching out",
    message: "We'll get back to you as soon as we can!",
  })
  return (
    <>
      <Header title={title} subtitle={subtitle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 31">
          <path d="M189.153 26.93c121.47 16.124 113.612-29.358 199.673-15.782 86.224 13.636 288.928-22.41 257.672-4.071-31.54 18.36-102.164 101.113-188.704 93.718-86.378-7.336-95.318-12.98-165.35-7.846-70.315 5.154-201.805 20.804-266.342-3.834-64.537-24.637-73.321-64.17-7.26-78.236 66.02-14.206 37.237-.054 170.311 16.05z" />
        </svg>
      </Header>
      <Breadcrumbs data={breadcrumbs} />
      <DetailedStepsPics data={detailedStepsPics} />
      <Features data={servicesAvailable} />
      <RecyclingShort data={commoditiesAvailable} />
      <FundraisingShort data={fundraisingShort} />
      {isCms ? null : (
        <Map
          data={map}
          short
          form={
            sent.status ? (
              <PostSubmit title={sent.title}>{sent.message}</PostSubmit>
            ) : (
              <ContactForm
                setSent={setSent}
                sent={sent}
                title="Drop us a line"
                formType="General"
              />
            )
          }
        />
      )}
    </>
  )
}

const LocationsPost = ({
  data: {
    markdownRemark: {
      frontmatter: {
        titleTag,
        subtitle,
        detailedStepsPics,
        servicesAvailable,
        commoditiesAvailable,
        fundraisingShort,
        map,
      },
    },
  },
  location,
}) => {
  const title = titleTag.split(" | ")[0]

  const breadcrumbs = location.pathname.split("/")
  const seoDesc = `${title} with the help of ReCollect picks up your cans and electronics, and direct deposits you the refund.`
  return (
    <Layout title={titleTag} seoDesc={seoDesc}>
      <LocationsPostTemplate
        title={title}
        subtitle={subtitle}
        detailedStepsPics={detailedStepsPics}
        servicesAvailable={servicesAvailable}
        commoditiesAvailable={commoditiesAvailable}
        fundraisingShort={fundraisingShort}
        map={map}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default LocationsPost

export const PageQuery = graphql`
  query LocationPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        titleTag
        subtitle
        detailedStepsPics {
          title
          subtitle
          data {
            title
            body
            image {
              relativePath
            }
          }
        }
        servicesAvailable {
          title
          data {
            name
            image {
              relativePath
            }
            url
            body
          }
        }
        commoditiesAvailable {
          title
          data {
            name
            body
            image {
              relativePath
            }
          }
        }
        fundraisingShort {
          title
          loc {
            city
            area
          }
        }
        map {
          title
          data {
            name
            address
            email
            phone
            url
            image {
              relativePath
            }
          }
        }
      }
    }
  }
`
