import React from "react"
import styled from "@emotion/styled"

import { createHTML } from "../utils"
import Cta from "./Cta"

const Container = styled.section`
  max-width: 130rem;
  margin: 3rem auto 9rem;
  padding: 0 1.5rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78B526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const SubtitleContainer = styled.div`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Content = styled.div`
  padding: 3rem 0;
`

const Steps = styled.ul`
  list-style: none;
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
`

const Item = styled.li`
  display: flex;
  position: relative;
  @media only screen and (max-width: 40em) {
    flex-direction: column;
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 0.25rem;
      background-color: ${(props) => (props.last ? "transparent" : "#505050")};
      top: 1.5rem;
      left: 0.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 50%;
      background-color: #505050;
      top: 1.5rem;
      left: 0;
      z-index: 2;
    }
  }
`

const Details = styled.div`
  position: relative;
  @media only screen and (min-width: 40em) {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 0.25rem;
      background-color: ${(props) => (props.last ? "transparent" : "#505050")};
      top: 1.5rem;
      left: 0.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 50%;
      background-color: #505050;
      top: 1.5rem;
      left: 0;
      z-index: 2;
    }
  }
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  color: #505050;
  padding: 0 3rem;
`

const Body = styled.div`
  padding: 1.5rem 3rem;
  font-weight: 400;
  color: #505050;
`

const Img = styled.img`
  height: 200px;
  padding: 15px 0;
  @media only screen and (max-width: 40em) {
    height: auto;
    width: 100%;
    padding: 15px 30px;
  }
`

const DetailedStepsPics = ({ data: { data, subtitle, title } }) => (
  <Container>
    <Primary>{title}</Primary>
    <SubtitleContainer dangerouslySetInnerHTML={createHTML(subtitle)}/>
    <Content>
      <Steps>
        {data.map(({ body, title, image }, count) => (
          <Item last={count === data.length - 1}>
            <Img src={`/img/${image.relativePath}`} alt={`Step ${count + 1}`} />
            <Details last={count === data.length - 1}>
              <Secondary>{title}</Secondary>
              <Body dangerouslySetInnerHTML={createHTML(body)} />
            </Details>
          </Item>
        ))}
      </Steps>
    </Content>
    <Cta href="https://app.re-collect.com.au/register">Get started</Cta>
  </Container>
)

export default DetailedStepsPics
